import React, { useImperativeHandle, useRef, forwardRef } from "react";
import Carousel, { CarouselProps } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface RxCarouselProps extends CarouselProps {
  ref: React.ForwardedRef<Carousel>;
}

const RxCarousel: React.FC<RxCarouselProps> = forwardRef(
  ({ children, ...props }, ref) => {
    const carouselRef = useRef(null);

    // expose prev/next outside this component
    useImperativeHandle(ref, () => ({
      ...carouselRef.current,
      next: () => {
        carouselRef.current.next();
      },
      previous: () => {
        carouselRef.current.previous();
      },
    }));

    return (
      <Carousel ref={carouselRef} {...props}>
        {children}
      </Carousel>
    );
  }
);

export default RxCarousel;
