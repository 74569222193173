import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import colours from "../../../utils/colours";

interface BusinessButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonText?: string;
  disabled?: boolean;
}

const BusinessButton: React.FC<BusinessButtonProps> = (props) => {
  return (
    <S.Button {...props}>{props.children || props.buttonText || null}</S.Button>
  );
};

interface StyleProps {
  disabled?: boolean;
}

const S = () => {};

S.Button = styled.button<StyleProps>`
  padding: 12px 30px;
  width: 200px;
  font-size: 1.6rem;
  color: ${colours.lightShell};
  background-color: #de5e60;
  border: 3px solid transparent;
  border-radius: 5px;
  transition: all 0.05s linear;

  &:focus-visible {
    outline: 3px solid black;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1);
  }

  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    opacity: 70%;
  `}
`;

export default BusinessButton;
