import React from "react";
import styled from "styled-components";
import Image from "next/image";

import { colours } from "../../../utils";
import { Device } from "../../../utils/device";
import { H2, CL, CXL } from "@/components/Typography";
import BusinessEnquiryForm from "./BusinessEnquiryForm";

type StyleProps = {
  hiddenOnMobile?: boolean;
  middleImage?: boolean;
  titleContent?: boolean;
  square?: boolean;
};

const BusinessHeroContent = () => {
  return (
    <S.Section>
      <S.CopyContainer>
        <S.ImageContainer>
          <Image
            src="/images/business/alliance/alliance-logo.png"
            alt="Logo for AllBright Alliance"
            layout="fill"
            objectFit="contain"
          />
        </S.ImageContainer>
        <S.Line />
        <CXL transform="uppercase" letterSpacing={6}>
          Powered by AllBright
        </CXL>
        <CL>
          Unite the world’s leading organisations to co-create the future of the
          workplace, fuelling innovation and diverse leadership at every level.
        </CL>
        <S.ImageContainer square hiddenOnMobile>
          <Image
            alt="Logo for AllBright Alliance"
            layout="fill"
            objectFit="contain"
            src="/images/business/alliance/alliance-img.png"
          />
        </S.ImageContainer>
      </S.CopyContainer>

      <S.FormContainer>
        <H2 colour={colours.shell}>
          Make an <S.Roxborough>enquiry</S.Roxborough>
        </H2>
        <BusinessEnquiryForm textColour={colours.shell} />
      </S.FormContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("/images/marketing/marketing-vector-navy.svg");
  background-color: #222b59;

  @media (min-width: ${Device.tablet}) {
    padding: 0 10% 5% 10%;
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: 10%;
  }

  @media (min-width: ${Device.desktop}) {
    grid-template-columns: 1fr 1.5fr;
  }
`;

S.CopyContainer = styled.div<StyleProps>`
  height: 100%;
  color: ${colours.shell};
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: center;

  @media (min-width: ${Device.tablet}) {
    padding: 5% 0;
    gap: 2rem;
  }

  @media (min-width: ${Device.desktop}) {
    padding: 5% 10%;
    gap: 2rem;
  }
`;

S.ImageContainer = styled.div<StyleProps>`
  position: relative;
  width: 100%;
  aspect-ratio: 2/1;

  @supports not (aspect-ratio: 2/1) {
    &:before {
      float: left;
      padding-top: 50%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  ${(p) =>
    p.square &&
    `
    aspect-ratio: 1;

    @supports not (aspect-ratio: 1) {
      &:before {
        float: left;
        padding-top: 100%;
        content: "";
      }
      &:after {
        display: block;
        content: "";
        clear: both;
      }
    }
  `}

  ${(p) =>
    p.hiddenOnMobile &&
    `
    display: none;

    @media(min-width: ${Device.tablet}) {
      display: block;
    }
  `}
`;

S.Line = styled.div`
  height: 0.5rem;
  width: 100%;
  background: ${colours.shell};
`;

S.FormContainer = styled.div`
  padding: 10% 5%;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: ${colours.marketingBusinessNavy};
  border: 1px solid ${colours.marketingBlue};
  border-radius: 1rem;

  @media (min-width: ${Device.tablet}) {
    padding: 10%;
    border-radius: 0 0 1rem 1rem;
  }
`;

S.Roxborough = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

export default BusinessHeroContent;
