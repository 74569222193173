import styled, { css } from "styled-components";
import { base as baseCssProps, BaseProps } from "@/components/Typography";

/**
 * Bootstrap 5 style layout, with Container, Row, Col components, including their props
 * To find out more see: https://react-bootstrap.netlify.app/docs/layout/breakpoints
 *
 * Example:
 * <RxContainer fluid>
 *   <RxRow gx={16}>
 *     <RxCol xs={12} md={2} />
 *     <RxCol xs={12} md={{ span: 2, offset: 1, order: 'last' }} />
 *   </RxRow>
 * </RxContainer>
 *
 * Tips:
 *  md=true in 1st column will take remaining width
 *  <RxCol xs={12} md />
 *  <RxCol xs={12} md={1} />
 *
 *  md="auto" in 1st column will take its content width
 *  <RxCol xs={12} md="auto" />
 *  <RxCol xs={12} md={1} />
 */
export type BreakpointsNames = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type Breakpoints = Partial<Record<BreakpointsNames, string>>; // all keys are optional

const breakpoints: Breakpoints = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

/**
 * The Container component provides a responsive and centered layout container.
 */
type ContainerProps = {
  /** Determines if the container is full width or has a max-width. */
  fluid?: boolean;
};

const RxContainer = styled.div<ContainerProps>`
  width: 100%;
  max-width: 1440px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  // By default, no max-width constraint when fluid
  ${(props) =>
    !props.fluid &&
    css`
      @media (min-width: ${breakpoints.sm}) {
        max-width: calc(576px - 30px);
      }
      @media (min-width: ${breakpoints.md}) {
        max-width: calc(768px - 30px);
      }
      @media (min-width: ${breakpoints.lg}) {
        max-width: calc(992px - 30px);
      }
      @media (min-width: ${breakpoints.xl}) {
        max-width: calc(1200px - 30px);
      }
      @media (min-width: ${breakpoints.xxl}) {
        max-width: calc(1400px - 30px);
      }
    `}
`;

/**
 * The Row component provides a horizontal layout of columns.
 */
type GutterValues =
  | number
  | {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
      xl?: number;
      xxl?: number;
    };

type RowProps = BaseProps & {
  gx?: GutterValues;
  gy?: GutterValues;
  g?: GutterValues;
  children: React.ReactNode;
};

// Helper function to divide spacing between columns and rows evenly
const generateGutterStyles = (
  gutter: GutterValues | undefined,
  type: "g" | "gx" | "gy"
) => {
  const baseStyle = (value: number) => {
    switch (type) {
      case "g":
        return `
        margin-top: ${(-1 * value) / 2}px;
        margin-right: -${value / 2}px;
        margin-left: -${value / 2}px;
        
        > * {
          padding-right: ${value / 2}px;
          padding-left: ${value / 2}px;
          margin-top: ${value}px;
        }
      `;
      case "gx":
        return `
        margin-right: -${value / 2}px;
        margin-left: -${value / 2}px;
        
        > * {
          padding-right: ${value / 2}px;
          padding-left: ${value / 2}px;
        }
      `;
      case "gy":
        return `
        > * {
          padding-top: ${value / 2}px;
          padding-bottom: ${value / 2}px;
        }
      `;
    }
  };

  if (typeof gutter === "number") {
    return baseStyle(gutter);
  } else if (gutter) {
    let styles = "";
    let previousValue: number | null = null;

    const breakpointOrder: BreakpointsNames[] = [
      "xs",
      "sm",
      "md",
      "lg",
      "xl",
      "xxl",
    ];
    for (const bp of breakpointOrder) {
      const value = gutter[bp];
      if (value !== undefined) {
        previousValue = value;
      }
      if (previousValue !== null) {
        styles += css`
          @media (min-width: ${breakpoints[bp]}) {
            ${baseStyle(previousValue)}
          }
        `;
      }
    }

    return styles;
  }
  return "";
};

const RxRow = styled.div<RowProps>`
  ${baseCssProps};

  display: flex;
  flex-wrap: wrap;

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  ${(props) => {
    // If `gx` or `gy` is defined, only use them.
    if (typeof props.gx !== "undefined" || typeof props.gy !== "undefined") {
      return `
        ${props.gx !== undefined ? generateGutterStyles(props.gx, "gx") : ""}
        ${props.gy !== undefined ? generateGutterStyles(props.gy, "gy") : ""}
      `;
    }
    // If neither `gx` nor `gy` is defined, use `g`.
    return generateGutterStyles(props.g, "g");
  }}
`;

RxRow.defaultProps = {
  g: 20,
};

/**
 * The Col component provides a flexible and responsive column layout.
 * It accepts props for various breakpoints: `sm`, `md`, `lg`, `xl`, `xxl`.
 * Each prop can define the width of the column for that breakpoint.
 */
type ColSpanValue = boolean | "auto" | number;

type ColValues =
  | ColSpanValue
  | {
      span?: ColSpanValue;
      offset?: number;
      order?: "first" | "last" | number;
    };

type ColProps = BaseProps & {
  /** Defines the width of the column. */
  span?: ColSpanValue;
  /** Defines the margin-left for the column. */
  offset?: number;
  /** Controls the visual order of the column. */
  order?: "first" | "last" | number;
  /** Responsive breakpoints for the column. */
  g?: number;
  /** Responsive breakpoints for the column. */
} & Partial<Record<BreakpointsNames, ColValues>>;

/**
 * Helper function to generate styles for the Col component.
 */
const generateColStyles = (props: ColProps, breakpoint?: BreakpointsNames) => {
  let colValue: ColValues | ColSpanValue | undefined;

  if (breakpoint) {
    colValue = props[breakpoint];
  } else {
    colValue = props.span;
  }

  if (!colValue) return "";

  let spanValue: ColSpanValue;
  if (typeof colValue === "object") {
    spanValue = colValue.span || "auto";
  } else {
    spanValue = colValue;
  }

  const spanPercentage = getPercentage(spanValue);

  return css`
    flex: ${spanValue === true
      ? "1"
      : spanValue === "auto"
        ? "0 0 auto"
        : `0 0 calc(${spanPercentage} - ${props.g ? props.g : 0}px)`};
    width: ${spanValue === true
      ? "auto"
      : spanValue === "auto"
        ? "auto"
        : `calc(${spanPercentage} - ${props.g ? props.g : 0}px)`};
    ${typeof colValue === "object" &&
    colValue.offset !== undefined &&
    `margin-left: ${getPercentage(colValue.offset)};`}
    ${typeof colValue === "object" &&
    colValue.order &&
    typeof colValue.order === "number" &&
    `order: ${colValue.order};`}
    ${typeof colValue === "object" &&
    colValue.order === "first" &&
    `order: -1;`}
    ${typeof colValue === "object" &&
    colValue.order === "last" &&
    `order: 13;`} // assuming a 12 column grid
  `;
};

const getPercentage = (value: ColSpanValue): string => {
  if (value === true) {
    return "auto"; // Let flexbox handle the width
  } else if (typeof value === "number") {
    return `${(value / 12) * 100}%`; // Convert column span value to percentage
  }
  return "100%"; // for "auto" and boolean 'false', make it take full width
};

const RxCol = styled.div<ColProps>`
  ${baseCssProps};

  position: relative;
  min-height: 1px;
  flex: 1 1 0; // By default, columns will take up equal space

  ${(props) => generateColStyles(props)} // Apply default styles

  @media (min-width: ${breakpoints.xs}) {
    ${(props) => generateColStyles(props, "xs")}
  }
  @media (min-width: ${breakpoints.sm}) {
    ${(props) => generateColStyles(props, "sm")}
  }
  @media (min-width: ${breakpoints.md}) {
    ${(props) => generateColStyles(props, "md")}
  }
  @media (min-width: ${breakpoints.lg}) {
    ${(props) => generateColStyles(props, "lg")}
  }
  @media (min-width: ${breakpoints.xl}) {
    ${(props) => generateColStyles(props, "xl")}
  }
  @media (min-width: ${breakpoints.xxl}) {
    ${(props) => generateColStyles(props, "xxl")}
  }
`;

export { breakpoints, RxContainer, RxRow, RxCol };
