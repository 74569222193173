import configClient from "../config.client";

/**
 * ProtectedApiClient is an abstract class used as a base for API clients
 * that require an API key to be sent in the headers of each request.
 *
 * It provides a common setup for the API endpoint, the API key, and the
 * Axios configuration object that includes the API key in the headers.
 */

abstract class ProtectedApiClient {
  protected apiEndpoint: string;
  protected apiKey: string;

  constructor() {
    this.apiEndpoint = configClient.ALLBRIGHT_API_URL;
    this.apiKey = configClient.ALLBRIGHT_API_KEY;
  }

  protected get axiosConfig() {
    return {
      headers: {
        "x-api-key": this.apiKey,
      },
    };
  }
}

export default ProtectedApiClient;
