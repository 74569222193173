import React, { FunctionComponent, InputHTMLAttributes } from "react";
import styled from "styled-components";

import { TriangleDown } from "../../Icons";
import colours from "../../../utils/colours";
import { Device } from "@/utils/device";
import { errorOutline } from "@/utils/errorOutline";

interface RxDropdownSelectProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  name: string;
  options: string[];
  selected: string;
  disabled?: boolean;
  error?: boolean;
  isMandatory?: boolean;
}

const RxDropdownSelect: FunctionComponent<RxDropdownSelectProps> = (props) => {
  const innerRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const filteredOptions = props.options.filter(
    (option) => option.toLowerCase().indexOf(searchText) === 0
  );

  React.useEffect(() => {
    if (!isOpen) return;
    document.addEventListener("click", onOutsideClick);

    return () => document.removeEventListener("click", onOutsideClick);

    function onOutsideClick(evt) {
      if (innerRef?.current?.contains(evt.target)) return;
      setIsOpen(false);
    }
  }, [isOpen]);

  return (
    <S.Container
      ref={innerRef}
      onClick={() => {
        if (!props.disabled) setIsOpen(!isOpen);
      }}
      data-cy={`${props.name}-dropdown-input`}
      $isOpen={isOpen}
      disabled={props.disabled}
      error={props.error}
    >
      {isOpen && (
        <S.SearchInput
          ref={(elm) => elm && elm.focus()}
          placeholder="Type to search..."
          onChange={(e) => setSearchText(e.target.value.toLowerCase())}
          name={props.name}
          value={searchText}
        />
      )}
      {!isOpen && <S.Value>{props.selected}</S.Value>}
      <S.Caption
        onClick={() => {
          if (!props.disabled) setIsOpen(true);
        }}
        $hasValue={!!searchText || !!props.selected}
        $isOpen={isOpen}
        disabled={props.disabled}
      >
        {props.labelText}
      </S.Caption>
      {!props.disabled && <S.TriangleDown fill="#E8DACF" isOpen={isOpen} />}
      <React.Fragment>
        <S.OptionsContainer $isOpen={isOpen}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((elm) => (
              <S.OptionContainer key={elm} onChange={() => setIsOpen(false)}>
                <S.Option
                  aria-disabled={props.disabled}
                  name={elm}
                  id={elm}
                  value={elm}
                  checked={props.selected === elm}
                  type="checkbox"
                  data-cy={`${elm}-checkbox`}
                  $isMandatory={props.isMandatory}
                  {...props}
                />
                <S.OptionLabel
                  htmlFor={elm}
                  data-cy={elm + "-label"}
                  disabled={props.disabled}
                >
                  {elm}
                </S.OptionLabel>
              </S.OptionContainer>
            ))
          ) : (
            <S.OptionContainer>
              <S.Option type="checkbox" disabled />
              <S.OptionLabel>Nothing matches your search</S.OptionLabel>
            </S.OptionContainer>
          )}
        </S.OptionsContainer>
      </React.Fragment>
    </S.Container>
  );
};

type StyleProps = {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  $isOpen?: boolean;
  $hasValue?: boolean;
  $isMandatory?: boolean;
};

const S = () => {};

S.Container = styled.div<StyleProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 17px;
  border: 1px solid ${colours.shellOverlay};
  border-radius: 5px;
  min-width: 220px;
  height: 49px;
  background-color: white;
  color: ${colours.charcoal};
  cursor: pointer;

  ${(p) =>
    p.$isOpen &&
    `
    border: 1px solid black; 
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}

  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    background-color: ${colours.shellOverlay};
    cursor: default;
  `} ${(p) =>
    p.error &&
    !p.$isOpen &&
    `
    ${errorOutline}
    border: none;
    border-radius: 5px;
  `} 
  
  @media (min-width: ${Device.mobile}) {
    height: 59px;
  }
`;

S.Value = styled.p`
  position: absolute;
  top: 18px;
  left: 14px;
  font-size: 1.4rem;
  font-weight: 500;

  @media (min-width: ${Device.mobile}) {
    top: 24px;
    font-size: 1.6rem;
  }
`;

S.Caption = styled.figcaption<StyleProps>`
  top: 20px;
  left: 16px;
  outline: none;
  overflow: hidden;
  color: ${colours.charcoal};
  font-size: 1.4rem;
  transition: all 0.2s ease-out;
  cursor: pointer;

  ${(p) =>
    (p.$isOpen || p.$hasValue) &&
    `
    position: absolute;
    top: 6px;
    left: 14px;
    color: ${colours.charcoal};
    font-size: 8px;
    letter-spacing: 0px;
  `}

  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    color: ${colours.charcoalMid};
    cursor: default;
  `}

  @media (min-width: ${Device.mobile}) {
    font-size: 1.6rem;

    ${(p) =>
      (p.$isOpen || p.$hasValue) &&
      `
        font-size: 12px;
    `}
  }
`;

S.TriangleDown = styled(TriangleDown)<StyleProps>`
  position: absolute;
  right: 20px;
  transition: all 0.2s ease-out;

  ${(p) =>
    p.$isOpen &&
    `
    transform: rotate(180deg)
  `}
`;

S.SearchInput = styled.input`
  width: 100%;
  height: 46px;
  outline: none;
  padding-top: 10px;
  padding-left: 16px;
  border: none;
  color: ${colours.charcoal};
  font-size: 1.4rem;

  &::placeholder {
    color: ${colours.charcoalMid};
    font-weight: 300;
  }

  @media (min-width: ${Device.mobile}) {
    font-size: 1.6rem;
    height: 56px;
  }
`;

S.OptionsContainer = styled.ul<StyleProps>`
  position: absolute;
  top: 48px;
  left: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px 0px;
  width: 100%;
  /* height: 100%; */
  max-height: ${(p) => (p.$isOpen ? "250px" : "0px")};
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;
  overflow-x: none;
  z-index: 1;
  transition: max-height 0.2s ease-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  ${(p) =>
    p.$isOpen &&
    `
    -webkit-box-shadow:
      -1px 0 0 black,
      1px 0 0 black,
      0 1px 0 black;

    -moz-box-shadow:
      -1px 0 0 black,
      1px 0 0 black,
      0 1px 0 black;

    box-shadow:
      -1px 0 0 black,
      1px 0 0 black,
      0 1px 0 black;
  `} @media(min-width: ${Device.mobile}) {
    top: 58px;
  }
`;

S.OptionContainer = styled.div`
  position: relative;
  height: 49px;

  @media (min-width: ${Device.mobile}) {
    height: 59px;
  }
`;

S.Option = styled.input<StyleProps>`
  min-height: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 100%;
  width: 100%;
  background-color: ${colours.white};
  cursor: pointer;

  ${(p) =>
    p.checked &&
    `
    background-color: ${colours.pillTeal};
    font-weight: 500;
  `}
  &:hover {
    background-color: ${(p) => !p.checked && colours.shell};
  }

  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    cursor: default;
  `} @media(min-width: ${Device.mobile}) {
    min-height: 40px;
  }
`;

S.OptionLabel = styled.label<StyleProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 1.4rem;
  top: 0;
  cursor: pointer;

  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
    cursor: default;
  `} @media(min-width: ${Device.mobile}) {
    font-size: 1.6rem;
  }
`;

export default RxDropdownSelect;
