import styled from "styled-components";
import { base as baseCssProps, BaseProps } from "@/components/Typography";

/**
 * Bootstrap 5 style img and all its props
 * To find out more see: https://react-bootstrap.netlify.app/docs/components/images
 */
type RxImgProps = React.ImgHTMLAttributes<HTMLImageElement> &
  BaseProps & {
    /** Makes the image fluid (100% width while maintaining the aspect ratio) */
    fluid?: boolean;
    /** Rounds the corners of the image */
    rounded?: boolean;
    /** Rounds the corners to a circle */
    roundedCircle?: boolean;
    /** Styles the image as a thumbnail (border and rounded corners) */
    thumbnail?: boolean;
  };

/**
 * The RxImg component provides a responsive image with support for different shapes.
 */
const RxImg = styled.img<RxImgProps>`
  display: block;
  max-width: 100%;
  height: auto;

  ${baseCssProps};

  ${(props) =>
    props.fluid &&
    `
    width: 100%;
  `}

  ${(props) =>
    props.rounded &&
    `
    border-radius: 0.25rem;
  `}

  ${(props) =>
    props.roundedCircle &&
    `
    border-radius: 50%;
  `}

  ${(props) =>
    props.thumbnail &&
    `
    padding: 0.25rem;
    background-color: #f9f9f9;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  `}
`;

export default RxImg;
