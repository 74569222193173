import styled, { css } from "styled-components";
import { BreakpointsNames, breakpoints } from "./RxLayout";

// standard breakpoints, all optional accepting those value
type DisplayValues = Partial<
  Record<
    BreakpointsNames,
    "block" | "inline" | "inline-block" | "flex" | "inline-flex" | "none"
  >
>;

/**
 * Takes and CSS display value per breakpoint block, none, inline, flex.
 * If xs="none" and sm="block" it will only be hidden on xs and visible for sm and higher
 *
 * Example:
 * <RxResponsiveDisplay xs="none" sm="block">Some Content</RxResponsiveDisplay>
 */
const display = (size: string, value: string) => css`
  @media (min-width: ${breakpoints[size]}) {
    display: ${value};
  }
`;

const RxResponsiveDisplay = styled.div<DisplayValues>`
  /* Default */
  ${(props) => props.xs && display("xs", props.xs)}

  /* Small devices */
  ${(props) => props.sm && display("sm", props.sm)}

    /* Medium devices */
  ${(props) => props.md && display("md", props.md)}

    /* Large devices */
  ${(props) => (props.lg || props.md) && display("lg", props.lg || props.md)}

    /* Extra large devices */
  ${(props) =>
    (props.xl || props.lg || props.md) &&
    display("xl", props.xl || props.lg || props.md)}

    /* Extra extra large devices */
  ${(props) =>
    (props.xxl || props.xl || props.lg || props.md) &&
    display("xxl", props.xxl || props.xl || props.lg || props.md)}
`;

export default RxResponsiveDisplay;
