import styled from "styled-components";

type TriangleProps = {
  direction?: "up" | "right" | "down" | "left";
  size?: number;
  color?: string;
};

const RxTriangle = styled.span<TriangleProps>`
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  cursor: pointer;

  ${(props) => {
    switch (props.direction) {
      case "up":
        return `
          border-width: 0 ${props.size / 2}px ${props.size}px ${props.size / 2}px;
          border-color: transparent transparent ${props.color} transparent;
        `;
      case "right":
        return `
          border-width: ${props.size / 2}px 0 ${props.size / 2}px ${props.size}px;
          border-color: transparent transparent transparent ${props.color};
        `;
      case "down":
        return `
          border-width: ${props.size}px ${props.size / 2}px 0 ${props.size / 2}px;
          border-color: ${props.color} transparent transparent transparent;
        `;
      case "left":
        return `
          border-width: ${props.size / 2}px ${props.size}px ${props.size / 2}px 0;
          border-color: transparent ${props.color} transparent transparent;
        `;
      default:
        return "";
    }
  }}
`;

RxTriangle.defaultProps = {
  direction: "down", // Default direction is down
  size: 20, // Default size
  color: "white", // Default color
};

export default RxTriangle;
