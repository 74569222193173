import React, { useRef } from "react";
import styled from "styled-components";

import config from "@/config.client";
import * as u from "@/utils";
import * as Sentry from "@sentry/browser";
import { DIV, H1, H2, H3, H5, SPAN } from "@/components/Typography";
import { RxContainer, RxRow, RxCol } from "@/components/ui/display/RxLayout";
import CrmClient from "@/client/CrmClient";
import RxImg from "@/components/ui/display/RxImg";
import RxTriangle from "@/components/ui/display/RxTriangle";
import RxResponsiveDisplay from "@/components/ui/display/RxResponsiveDisplay";
import RxPageLayout from "@/components/ui/display/RxPageLayout";
import RxCarousel from "@/components/ui/display/RxCarousel";
import RxTextInput from "@/components/ui/inputs/RxTextInput";
import BusinessButton from "@/components/marketing/business/BusinessButton";
import { addressBookSchema } from "@/schemas/marketing/newsletterSchema";
import * as gtag from "@/utils/gtag";
import { getJoiErrorMessage } from "@/handlers/joiErrorHandler";
import BusinessHeroContent from "@/components/marketing/business/BusinessHeroContent";
import Modal from "@/components/Modal";
import MarketingFormContainer from "@/components/marketing/MarketingFormContainer";
import BusinessEnquiryForm from "@/components/marketing/business/BusinessEnquiryForm";
import MarketingButton from "@/components/marketing/MarketingButton";

// All banners have the same action
type BusinessProps = object;

const formTabs = [
  {
    activeColour: u.colours.marketingBusinessNavy,
    content: <BusinessEnquiryForm buttonColour={u.colours.charcoal} />,
    title: "Make an enquiry",
  },
];

const Business: React.FC<BusinessProps> = (): JSX.Element => {
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState<string[]>([]);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formDisabled, setFormDisabled] = React.useState(false);
  const [formModalOpen, setFormModalOpen] = React.useState(false);

  const onSubmit = async (event) => {
    setFormDisabled(true);
    event.preventDefault();
    if (isLoading || (await validateForm())) return;
    setIsLoading(true);

    try {
      const client = new CrmClient();
      const addressBook =
        config.DOT_DIGITAL.ADDRESS_BOOKS.alliance_newsletter.id;
      await client.subscribeCustomer(email, addressBook);

      gtag.event({
        name: gtag.GA_EVENT.MARKETING_ENGAGEMENT,
        category: "Newsletter",
        label: "Signed up to future of women report",
        value: 1,
      });

      setSuccess(true);
    } catch (err) {
      Sentry.captureException(err);
      setFormDisabled(false);
      setErrors([
        "Sorry, we were unable to give you access at this time. Please come back later",
      ]);
    }

    return setIsLoading(false);
  };

  const validateForm = async () => {
    const maybeErrors: string[] = [];

    try {
      await addressBookSchema.validateAsync(
        {
          email: email,
        },
        { abortEarly: false }
      );
    } catch (errors) {
      setFormDisabled(false);
      maybeErrors.push(getJoiErrorMessage("email", errors.details));
    }

    setErrors(maybeErrors);
    setIsLoading(false);
    return maybeErrors.length > 0;
  };

  return (
    <RxPageLayout>
      {formModalOpen && (
        <>
          <Modal
            onClose={() => setFormModalOpen(false)}
            colour={u.colours.shellHighlight}
          >
            <S.ModalContent>
              <MarketingFormContainer tabs={formTabs} />
            </S.ModalContent>
          </Modal>
        </>
      )}
      <BusinessHeroContent />

      {/* transforming */}
      <DIV background="#FDF1EA" padding="60px 10%">
        <RxContainer fluid>
          <RxRow>
            <RxCol xs={12} md={6} lg={7} style={{ zIndex: 1 }}>
              <DIV margin="40px 0">
                <DIV
                  weight={800}
                  lineHeight={0.8}
                  color="#1F2755"
                  responsiveFonts={{ min: 45, max: 80 }}
                >
                  Co-creating a future of work
                  <br />
                  <SPAN roxborough>
                    <SPAN weight={300} fontSize="54px" color="#DE5E60">
                      <i>where all genders thrive</i>
                    </SPAN>
                  </SPAN>
                </DIV>
              </DIV>

              <DIV maxWidth={490} margin="40px 0">
                <p style={{ marginBottom: 20 }}>
                  Our award nominated Alliance programme harnesses the power of
                  data to provide a comprehensive framework for reshaping the
                  workplace. This framework centres around learning programmes,
                  talent engagement, leadership development, and talent
                  retention.
                </p>

                <p>
                  Proven to deliver lasting change and positive business
                  results.
                </p>
              </DIV>
              <MarketingButton
                onClick={() => setFormModalOpen(true)}
                colour={u.colours.corporateOrange}
                animationColour={u.colours.shell}
                buttonText="Find out more"
                name="modal_button"
                textColour={u.colours.marketingBusinessNavy}
              />
            </RxCol>
            <RxCol
              xs={12}
              md={6}
              lg={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <RxImg
                fluid
                src="/images/business/transforming-workplace-img.png"
              />
            </RxCol>
          </RxRow>
        </RxContainer>
      </DIV>

      {/* Optimising business */}
      <DIV
        background="#0C1858"
        textAlign="center"
        color="white"
        padding="60px 10%"
      >
        <RxContainer fluid>
          <RxRow>
            <RxCol>
              <H1
                weight={800}
                responsiveFonts={{ min: 41, max: 80 }}
                marginBottom={40}
              >
                Optimising business
                <br />
                <SPAN weight={200} roxborough>
                  <i>for success</i>
                </SPAN>
              </H1>

              <p style={{ marginBottom: 20 }}>
                This is more than an investment; it’s a partnership to crafting
                a future where everyone regardless of their background can
                thrive. It's an invitation for you to be part of reshaping the
                corporate landscape for the better.
              </p>

              <p style={{ marginBottom: 40 }}>
                <b>Our Alliance programme has been proven to support…</b>
              </p>

              <RxContainer fluid style={{ marginBottom: 30 }}>
                <RxRow gx={16} gy={20}>
                  <RxCol xs={12} sm={6} lg={3}>
                    <DIV
                      background="transparent url('/images/business/arrow-down-bg.png') no-repeat bottom center / cover"
                      padding="30px 30px 50px"
                      height="100%"
                    >
                      <H3 marginBottom={20}>
                        50% increase in female colleagues
                      </H3>
                      Organisations that prioritise the advancement of women
                      report as much as 61% higher revenue growth than their
                      competitors.
                    </DIV>
                  </RxCol>

                  <RxCol xs={12} sm={6} lg={3}>
                    <DIV
                      background="transparent url('/images/business/arrow-down-bg.png') no-repeat bottom center / cover"
                      padding="30px 30px 50px"
                      height="100%"
                    >
                      <h3 style={{ marginBottom: 20 }}>
                        40% board level female representation
                      </h3>
                      For every 1% increase in the proportion of women in the
                      top management, a company’s innovation revenue increases
                      by approximately 3.5%
                    </DIV>
                  </RxCol>

                  <RxCol xs={12} sm={6} lg={3}>
                    <DIV
                      background="transparent url('/images/business/arrow-down-bg.png') no-repeat bottom center / cover"
                      padding="30px 30px 50px"
                      height="100%"
                    >
                      <H3 marginBottom={20}>
                        Higher productivity and profitability
                      </H3>
                      In 2022, 73% of businesses with gender equity measures and
                      support structures in place reported higher productivity
                      and profitability.
                    </DIV>
                  </RxCol>

                  <RxCol xs={12} sm={6} lg={3}>
                    <DIV
                      background="transparent url('/images/business/arrow-down-bg.png') no-repeat bottom center / cover"
                      padding="30px 30px 50px"
                      height="100%"
                    >
                      <H3 marginBottom={20}>
                        68% more likely to retain talent
                      </H3>
                      A Deloitte study found that organisations with strong
                      gender diversity and inclusivity programmes were 68% more
                      likely to have high employee retention rates.
                    </DIV>
                  </RxCol>
                </RxRow>
              </RxContainer>

              <MarketingButton
                onClick={() => setFormModalOpen(true)}
                colour={u.colours.corporateOrange}
                animationColour={u.colours.shell}
                buttonText="Enquire now"
                name="modal_button"
                textColour={u.colours.shell}
              />
            </RxCol>
          </RxRow>
        </RxContainer>
      </DIV>

      {/* Better for teams */}
      <DIV padding="60px 10%">
        <RxContainer fluid>
          <RxRow marginBottom={50}>
            <RxCol xs={12} md={8} lg={9}>
              <DIV maxWidth={600}>
                <H1
                  marginBottom={40}
                  lineHeight={1}
                  responsiveFonts={{ min: 47, max: 72 }}
                >
                  <SPAN weight={800}>Better for</SPAN>{" "}
                  <SPAN weight={200} roxborough>
                    <i>teams</i>,<br />
                    better for business
                  </SPAN>
                </H1>

                <p style={{ marginBottom: 20 }}>
                  We provide the tools to increase performance, achieve goals
                  and unlock potential, through truly inclusive workplaces. We
                  believe that caring about your teams’ experience and investing
                  in your people, is an investment in your company’s success.
                </p>

                <p style={{ marginBottom: 50 }}>
                  Our comprehensive suite of consulting services, interactive
                  workshops and products are designed to meet the organisational
                  and individual needs.
                </p>

                <MarketingButton
                  onClick={() => setFormModalOpen(true)}
                  colour={u.colours.corporateOrange}
                  animationColour={u.colours.shell}
                  buttonText="Find out more"
                  name="modal_button"
                  textColour={u.colours.charcoal}
                />
              </DIV>
            </RxCol>

            <RxCol xs={12} md={4} lg={3}>
              <div
                style={{
                  borderBottom: "4px solid #DE5E60",
                  padding: "30px 20px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <div style={{ minWidth: 80, marginRight: 25 }}>
                  <RxImg src="/images/business/data-icon.svg" />
                </div>
                DATA AND RESEARCH
              </div>
              <div
                style={{
                  borderBottom: "4px solid #DE5E60",
                  padding: "30px 20px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <div style={{ minWidth: 80, marginRight: 25 }}>
                  <RxImg src="/images/business/networking-icon.svg" />
                </div>
                NETWORKING AND COMMUNITY
              </div>
              <div
                style={{
                  borderBottom: "4px solid #DE5E60",
                  padding: "30px 20px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <div style={{ minWidth: 80, marginRight: 25 }}>
                  <RxImg src="/images/business/upskilling-icon.svg" />
                </div>
                UPSKILLING AND EVENTS
              </div>
              <div
                style={{
                  borderBottom: "4px solid #DE5E60",
                  padding: "30px 20px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    minWidth: 80,
                    marginRight: 25,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <RxImg src="/images/business/talent-icon.svg" />
                </div>
                <div>TALENT PIPELINE SUPPORT</div>
              </div>
            </RxCol>
          </RxRow>

          <H1>Supporting next steps through learning</H1>
          <H3 style={{ marginBottom: 30 }}>
            Learning & Development Programmes at AllBright
          </H3>

          <RxRow>
            <RxCol
              xs={12}
              sm={6}
              md={4}
              lg={3}
              padding={35}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              color="white"
              height="100%"
              background="#F5AD97"
            >
              <b>ACCELERATE</b>
            </RxCol>
            <RxCol xs={12} sm={6} md={8} lg={9} paddingTop="12px">
              <DIV weight="bold">For managers or mid-level professionals.</DIV>
              <DIV>
                With tailored coaching, mentorship, and skill-building
                opportunities, Accelerate will equip your female employees to
                develop skills, explore their strengths and values, and gain
                greater self-knowledge.
              </DIV>
            </RxCol>
          </RxRow>

          <RxRow>
            <RxCol
              xs={12}
              sm={6}
              md={4}
              lg={3}
              padding={35}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              color="white"
              height="100%"
              background="#5869AD"
            >
              <b>ELEVATE</b>
            </RxCol>
            <RxCol xs={12} sm={6} md={8} lg={9} paddingTop="12px">
              <DIV weight="bold">For senior leaders or C-suite executives.</DIV>
              <DIV>
                A high-impact leadership development programme, Elevate provides
                the space, skills and connections needed to unlock your female
                employees’ potential.
              </DIV>
            </RxCol>
          </RxRow>

          <RxRow>
            <RxCol
              xs={12}
              sm={6}
              md={4}
              lg={3}
              padding={35}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              color="white"
              height="100%"
              background="#0C1858"
            >
              <b>ELEVATE TO THE BOARD</b>
            </RxCol>
            <RxCol xs={12} sm={6} md={8} lg={9} paddingTop="12px">
              <DIV weight="bold">
                For Elevate members or alumni, ready for their first or next
                board position.
              </DIV>
              <DIV>
                Elevate to the Board is an immersive learning programme designed
                to serve as the catalyst for senior women to progress to
                influential board roles.
              </DIV>
            </RxCol>
          </RxRow>

          <RxRow>
            <RxCol
              xs={12}
              sm={6}
              md={4}
              lg={3}
              padding={35}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              color="white"
              height="100%"
              background="#212434"
            >
              <b>REIMAGINING LEADERSHIP</b>
            </RxCol>
            <RxCol xs={12} sm={6} md={8} lg={9} paddingTop="12px">
              <DIV weight="bold">
                For all business leaders, regardless of gender.
              </DIV>
              <DIV>
                A leadership programme that develops and harnesses traits
                typically associated with feminine leadership, critical to
                success for today’s leader.
              </DIV>
            </RxCol>
          </RxRow>

          <RxRow marginBottom={40}>
            <RxCol
              xs={12}
              sm={6}
              md={4}
              lg={3}
              padding={35}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              color="white"
              height="100%"
              background="#DE5E60"
            >
              <b>REIGNITE</b>
            </RxCol>
            <RxCol xs={12} sm={6} md={8} lg={9} paddingTop="12px">
              <DIV weight="bold">For women returning to the workforce.</DIV>
              <DIV>
                Supporting those returning to the workforce with the tools and
                network to navigate their return with confidence and resilience.
              </DIV>
            </RxCol>
          </RxRow>

          <MarketingButton
            onClick={() => setFormModalOpen(true)}
            colour={u.colours.marketingBusinessNavy}
            animationColour={u.colours.shell}
            buttonText="Enquire now"
            name="modal_button"
            textColour={u.colours.charcoal}
          />
        </RxContainer>
      </DIV>

      {/* Hear from our Learners */}
      <Testimonials />

      {/* Tailored solutions */}
      <DIV background="#DE5E60" color={u.colours.shell} padding="70px 10%">
        <RxContainer fluid>
          <RxRow>
            <RxCol>
              <H5>
                <SPAN weight={800}>Get in touch | </SPAN>
                <SPAN weight={300}>
                  <i>To discuss your bespoke Alliance options</i>
                </SPAN>
              </H5>
              <DIV
                weight={800}
                fontSize="63px"
                lineHeight={1}
                margin="40px 0"
                responsiveFonts={{ min: 41, max: 63 }}
              >
                Tailored solutions
                <br />
                <SPAN weight={500}>to suit your business needs</SPAN>
              </DIV>
              <MarketingButton
                onClick={() => setFormModalOpen(true)}
                colour={u.colours.marketingBusinessNavy}
                animationColour={u.colours.shell}
                buttonText="Enquire now"
                name="modal_button"
                textColour={u.colours.shell}
              />
            </RxCol>
          </RxRow>
        </RxContainer>
      </DIV>

      {/* Case Studies */}
      <CaseStudies />

      {/* Be the brand */}
      <DIV background="#212434" color="#F2E5DD" padding="60px 10%">
        <RxContainer fluid>
          <RxRow>
            <RxCol>
              <H1
                weight={800}
                lineHeight={1}
                responsiveFonts={{ min: 50, max: 100 }}
              >
                <SPAN color="#DE5E60" weight={200} roxborough>
                  Join brands
                </SPAN>{" "}
                that have committed to{" "}
                <SPAN roxborough weight={100}>
                  <i>purposeful</i>
                </SPAN>{" "}
                change.
              </H1>

              <H3 margin="60px 0 20px">
                Our partners represent 500,000 employees
              </H3>

              <RxContainer fluid>
                <RxRow g={30} justifyContent="center">
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/eldridge.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/bny-mellon.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/bridgepoint.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/bromford.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/simba.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/chelsea.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/hsbc.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/smyle.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/visionable.svg"
                      alt=""
                    />
                  </RxCol>
                  <RxCol
                    xs={4}
                    sm={3}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RxImg
                      fluid
                      src="/images/business/partners/new-marketing-site/soho house.svg"
                      alt=""
                    />
                  </RxCol>
                </RxRow>
              </RxContainer>
            </RxCol>
          </RxRow>
        </RxContainer>
      </DIV>

      {/* Future of work for women */}
      <DIV
        background='#F2E1D7 url("/images/business/future-work-women-bg.png") no-repeat right 150px /cover'
        padding="70px 10%"
        id="future-of-work"
      >
        <RxContainer fluid>
          <RxRow>
            <RxCol
              xs={{ span: 12, order: 1 }}
              md={{ span: "auto", order: -1 }}
              display="flex"
              justifyContent="center"
              alignItems="start"
            >
              <RxImg
                fluid
                src="/images/business/future-of-work-report-alone.png"
                maxWidth="380px"
              />
            </RxCol>
            <RxCol>
              <DIV maxWidth="540px" marginBottom={300}>
                <DIV
                  weight={800}
                  fontSize="80px"
                  lineHeight={1}
                  borderBottom="10px solid #DA5D5D"
                  marginBottom="30px"
                  responsiveFonts={{ min: 60, max: 80 }}
                >
                  <SPAN weight={300} roxborough>
                    Future
                    <br />
                    <i>of work</i>
                  </SPAN>
                  <br />
                  for women
                </DIV>
                <DIV weight="bold" marginBottom={30}>
                  Are you ready to create a more inclusive and equitable
                  workplace? It’s not just for women.
                </DIV>
                <p style={{ marginBottom: 30 }}>
                  Gain valuable insights and strategies to attract, engage and
                  retain top female talent for better business outcomes.
                </p>
                <form onSubmit={onSubmit}>
                  <RxTextInput
                    labelText="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <BusinessButton
                    disabled={formDisabled}
                    buttonText="Access full report"
                    type="submit"
                    style={{ margin: "10px 0 5px 0" }}
                  >
                    {success && "subscribed ✔"}
                  </BusinessButton>
                  {errors.map((error) => (
                    <p
                      style={{ color: "red" }}
                      data-cy="error-message"
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </form>
              </DIV>
            </RxCol>
          </RxRow>
        </RxContainer>
      </DIV>

      {/* Join Alliance */}
      <DIV background="#DE5E60" color={u.colours.shell} padding="70px 10%">
        <RxContainer fluid>
          <RxRow>
            <RxCol>
              <H5>
                <SPAN weight={800}>Get in touch |</SPAN>
                <SPAN weight={300}>
                  <i>To discuss your bespoke Alliance options</i>
                </SPAN>
              </H5>
              <DIV
                weight={800}
                fontSize="63px"
                lineHeight={1}
                margin="40px 0"
                responsiveFonts={{ min: 41, max: 70 }}
              >
                Join Alliance
                <br />
                <SPAN weight={500}>
                  powered by AllBright&nbsp;
                  <SPAN weight={300} roxborough>
                    <i>now</i>
                  </SPAN>
                </SPAN>
              </DIV>
              <MarketingButton
                onClick={() => setFormModalOpen(true)}
                colour={u.colours.marketingBusinessNavy}
                animationColour={u.colours.shell}
                buttonText="Enquire now"
                name="modal_button"
                textColour={u.colours.shell}
              />
            </RxCol>
          </RxRow>
        </RxContainer>
      </DIV>
    </RxPageLayout>
  );
};

const Testimonials: React.FC = () => {
  const carouselRef = useRef(null);
  const [formModalOpen, setFormModalOpen] = React.useState(false);

  const onArrowClick = (direction: "left" | "right") => {
    if (carouselRef.current) {
      if (direction === "left") {
        carouselRef.current.previous();
      } else {
        carouselRef.current.next();
      }
    }
  };

  return (
    <DIV background="#0C1858" padding="70px 10%">
      {formModalOpen && (
        <>
          <Modal
            onClose={() => setFormModalOpen(false)}
            colour={u.colours.shellHighlight}
          >
            <S.ModalContent>
              <MarketingFormContainer tabs={formTabs} />
            </S.ModalContent>
          </Modal>
        </>
      )}
      <RxContainer fluid>
        <RxRow>
          <RxCol xs={12} md={10} color={u.colours.shell}>
            <H1 weight={800} marginBottom="50px">
              Hear from our Learners
            </H1>

            <RxCarousel
              ref={carouselRef}
              arrows={false}
              infinite
              ssr
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
            >
              <div>
                <H2 weight={500} marginBottom={45}>
                  <SPAN weight={800}>
                    “It's the best career programme I've ever done.
                  </SPAN>
                  &nbsp;I am so grateful to Bromford for the opportunity to
                  participate.
                  <SPAN weight={800}>
                    &nbsp;We had an exceptional group of women from a variety of
                    different organisations
                  </SPAN>{" "}
                  ranging from those who had just finished their graduate
                  placement right up to senior leaders, but everyone went
                  through moments of showing their vulnerabilities and
                  validating their strengths.
                </H2>
                <H2 weight={500} marginBottom={45}>
                  It’s a really valuable experience, but you have to be ready to
                  be honest with yourself, and you will be deeply rewarded by
                  what this special course has to offer.”
                </H2>
                <H3>Accelerate Learner, Bromford</H3>
                <DIV fontSize="small" marginBottom={40}>
                  Learner Testimonial
                </DIV>
              </div>

              <div>
                <H2 weight={800} marginBottom={45}>
                  “I started my new job in a new division after 1.5 years out of
                  corporate employment.
                </H2>
                <H2 weight={500} marginBottom={45}>
                  The Reignite Community has been so helpful connecting with
                  other females who have faced obstacles and shared their
                  perspectives, and finding a mentor in my industry!”
                </H2>
                <H3>Reignite Learner, Google</H3>
                <DIV fontSize="small" marginBottom={40}>
                  Learner Testimonial
                </DIV>
              </div>
            </RxCarousel>

            <MarketingButton
              onClick={() => setFormModalOpen(true)}
              colour={u.colours.corporateOrange}
              animationColour={u.colours.shell}
              buttonText="Enquire now"
              name="modal_button"
              textColour={u.colours.shell}
            />
          </RxCol>
          <RxCol
            xs={12}
            md={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <RxResponsiveDisplay xs="none" md="block">
              <RxTriangle
                direction="right"
                size={60}
                color="#FCA489"
                onClick={() => onArrowClick("right")}
              />
            </RxResponsiveDisplay>
          </RxCol>
        </RxRow>
      </RxContainer>
    </DIV>
  );
};

const CaseStudies: React.FC = () => {
  const carouselRef = useRef(null);

  const onArrowClick = (direction: "left" | "right") => {
    if (carouselRef.current) {
      if (direction === "left") {
        carouselRef.current.previous();
      } else {
        carouselRef.current.next();
      }
    }
  };

  return (
    <DIV background="#FDF1EA" padding="70px 10%">
      <RxContainer fluid>
        <RxRow>
          <RxCol>
            <H1 color={u.colours.marketingNavy} weight={800} marginBottom={70}>
              Case Studies: Evidence of our impact
            </H1>
          </RxCol>
        </RxRow>

        <RxRow>
          <RxCol xs={12} md={10}>
            <RxCarousel
              ref={carouselRef}
              arrows={false}
              infinite
              ssr
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
            >
              {/* slides */}
              <div>
                <RxContainer fluid>
                  <RxRow g={40}>
                    <RxCol xs={12} md={4}>
                      <RxImg src="/images/google-logo.png" fluid alt=" " />
                    </RxCol>

                    <RxCol>
                      <DIV weight={800} marginBottom={30}>
                        CHALLENGE:
                        <br />
                        Google aimed to support women returning to work after
                        career breaks,&nbsp;
                        <SPAN weight={500}>
                          particularly following maternity, parental leave, and
                          caregiving responsibilities.
                        </SPAN>
                      </DIV>

                      <DIV weight={800} marginBottom={30}>
                        SOLUTION:
                        <br />
                        Google offered 50 spots in their Digital Marketing &
                        E-commerce certificate course to women in the AllBright
                        community.&nbsp;
                        <SPAN weight={500}>
                          AllBright developed a coaching programme, managed the
                          learners, and created the Reignite partnership
                          programme.
                        </SPAN>
                      </DIV>

                      <DIV weight={800} marginBottom={30}>
                        RESULT:
                        <br />
                        <SPAN weight={500}>
                          246 women applied and are completing the Reignite
                          pilot programme,
                        </SPAN>{" "}
                        surpassing average learning hours by 1019%&nbsp;
                        <SPAN weight={500}>
                          compared to similar programmes.
                        </SPAN>{" "}
                        36% completed the course early, compared to the usual
                        3-6%.
                      </DIV>
                    </RxCol>
                  </RxRow>
                </RxContainer>
              </div>

              {/* Bromford */}
              <div>
                <RxContainer fluid>
                  <RxRow g={40}>
                    <RxCol xs={12} md={4}>
                      <RxImg
                        src="/images/business/logo-bromford.png"
                        fluid
                        alt=" "
                      />
                    </RxCol>

                    <RxCol>
                      <DIV weight={800} marginBottom={30}>
                        CHALLENGE:
                        <br />
                        Gender imbalance in the construction industry{" "}
                        <SPAN weight={500}>
                          (only 13% women) affected senior roles, pay gaps, and
                          career progression at Bromford.
                        </SPAN>
                      </DIV>

                      <DIV weight={800} marginBottom={30}>
                        SOLUTION:
                        <br />
                        Bromford partnered with us{" "}
                        <SPAN weight={500}>
                          for Career Accelerator, a 10-month programme for
                          junior to mid-level women, boosting skills and
                          self-awareness.
                        </SPAN>
                      </DIV>

                      <DIV weight={800} marginBottom={30}>
                        RESULT:
                        <br />
                        Bromford reduced their gender pay gap by 2% in under a
                        year{" "}
                        <SPAN weight={500}>
                          (national average 0.3%). Nominated for Women in
                          Housing Awards and awarded "UK's Best Place to Work
                          for Women" by Great Places to Work UK.
                        </SPAN>
                      </DIV>
                    </RxCol>
                  </RxRow>
                </RxContainer>
              </div>
            </RxCarousel>
          </RxCol>

          <RxCol
            xs={12}
            md={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <RxResponsiveDisplay xs="none" md="block">
              <RxTriangle
                direction="right"
                size={60}
                color={u.colours.marketingNavy}
                onClick={() => onArrowClick("right")}
              />
            </RxResponsiveDisplay>
          </RxCol>
        </RxRow>
      </RxContainer>
    </DIV>
  );
};

const S = () => {};

S.ModalContent = styled.div`
  padding: 5% 0;
`;

export default Business;
