import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import ProtectedApiClient from "./ProtectedApiClient";

type CustomerData = { [key: string]: string | number | boolean };

class CrmClient extends ProtectedApiClient {
  public async createCustomer(
    email: string,
    data: CustomerData
  ): Promise<number | null> {
    try {
      const response = await axios.post(
        `${this.apiEndpoint}/v1/crm/customer`,
        { email, data },
        this.axiosConfig
      );

      return response.data?.customerId || null;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag("where", "CrmClient.createCustomer");
        scope.setExtra("email", email);
        scope.setExtra("data", data);
        scope.setExtra("apiEndpoint", this.apiEndpoint);
        Sentry.captureException(error);
      });

      throw new Error("Unable to create customer at this time.");
    }
  }

  public async subscribeCustomer(
    email: string,
    addressBook: number
  ): Promise<number | null> {
    try {
      const response = await axios.post(
        `${this.apiEndpoint}/v1/crm/customer/subscribe`,
        { email, addressBook: addressBook },
        this.axiosConfig
      );

      return response.data?.subscribeId || null;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag("where", "CrmClient.subscribeCustomer");
        scope.setExtra("email", email);
        scope.setExtra("addressBook", addressBook);
        scope.setExtra("apiEndpoint", this.apiEndpoint);
        Sentry.captureException(error);
      });

      throw new Error(
        "Unable to subscribe a customer to a customer list (address book) at this time."
      );
    }
  }

  public async enrollInProgram(
    programId: number,
    contacts: number[]
  ): Promise<boolean> {
    try {
      const response = await axios.post(
        `${this.apiEndpoint}/v1/crm/program/enroll`,
        { programId, contacts },
        this.axiosConfig
      );
      return response.data.programId === programId;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag("where", "CrmClient.enrollInProgram");
        scope.setExtra("programId", programId);
        scope.setExtra("contacts", contacts);
        scope.setExtra("apiEndpoint", this.apiEndpoint);
        Sentry.captureException(error);
      });

      throw new Error("Unable to enroll in the program at this time.");
    }
  }
}

export default CrmClient;
