import React, { FunctionComponent } from "react";
import styled from "styled-components";

import * as gtag from "@/utils/gtag";
import RxTextInput from "@/components/ui/inputs/RxTextInput";
import RxPhoneNumberInput from "@/components/ui/inputs/RxPhoneNumberInput";
import RxCheckbox from "@/components/ui/inputs/RxCheckbox";
import RxLink from "@/components/ui/inputs/RxLink";
import { CM, H3 } from "@/components/Typography";
import MarketingButton from "../MarketingButton";
import { colours } from "@/utils";
import { getJoiErrorMessage } from "@/handlers/joiErrorHandler";
import businessSchema from "@/schemas/marketing/businessSchema";
import { getQueryParams } from "@/handlers/router/queryHandler";
import { useRouter } from "next/router";
import EnquiryClient from "@/client/EnquiryClient";
import RxError from "@/components/ui/display/RxError";
import RxDropdownSelect from "@/components/ui/inputs/RxDropdownSelect";

type BusinessEnquiryFormProps = {
  buttonColour?: string;
  textColour?: string;
};

const reasons = [
  "Word of mouth",
  "Social Media",
  "Email",
  "Referral",
  "Event / conference",
  "Website",
  "Search engine (ie Google)",
  "Digital Ad",
  "Media Coverage",
];

const BusinessEnquiryForm: FunctionComponent<BusinessEnquiryFormProps> = (
  props
) => {
  const router = useRouter();
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [company, setCompany] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [currentErrors, setCurrentErrors] = React.useState([]);

  const checkErrorsForField = (field) => {
    return currentErrors.map((error) => error.type).includes(field);
  };

  const removeFieldFromErrors = (field) => {
    setCurrentErrors(currentErrors.filter((error) => error.type !== field));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const request = {
      first_name: firstName,
      last_name: lastName,
      company: company,
      job_title: jobTitle,
      email: email,
      phone_number: phoneNumber,
      terms: termsAgreed || false,
      reason: reason,
      query_params: getQueryParams(router),
    };

    const maybeErrors = [];

    try {
      await businessSchema.validateAsync(request, { abortEarly: false });
    } catch (errors) {
      maybeErrors.push({
        type: "firstName",
        message: getJoiErrorMessage("first_name", errors.details),
      });
      maybeErrors.push({
        type: "lastName",
        message: getJoiErrorMessage("last_name", errors.details),
      });
      maybeErrors.push({
        type: "company",
        message: getJoiErrorMessage("company", errors.details),
      });
      maybeErrors.push({
        type: "jobTitle",
        message: getJoiErrorMessage("job_title", errors.details),
      });
      maybeErrors.push({
        type: "phoneNumber",
        message: getJoiErrorMessage("phone_number", errors.details),
      });
      maybeErrors.push({
        type: "reason",
        message: getJoiErrorMessage("alliance_contact_reason", errors.details),
      });
      maybeErrors.push({
        type: "email",
        message: getJoiErrorMessage("email", errors.details),
      });
    }

    if (maybeErrors.length > 0) {
      setIsLoading(false);
      setCurrentErrors(maybeErrors.filter((error) => error.message));
      return;
    }

    const client = new EnquiryClient();
    const response = await client.enquireAboutAlliance(request);

    if (!response) {
      setIsLoading(false);
      setCurrentErrors(["Something went wrong, please try again"]);
      return;
    }

    gtag.event({
      name: gtag.GA_EVENT.MARKETING_ENGAGEMENT,
      category: "Business Enquiry",
      label: "Alliance",
      value: 1,
    });

    setSuccess(true);
    setIsLoading(false);
  };

  return (
    <S.Form>
      {success ? (
        <H3 colour={props.buttonColour || colours.shell} marginTop="50px">
          Thank you for your enquiry. A member of our team will be in touch
          shortly.
        </H3>
      ) : (
        <>
          <S.TwoFieldsContainer>
            <RxTextInput
              labelText="First name *"
              name="first_name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                removeFieldFromErrors("firstName");
              }}
              error={checkErrorsForField("firstName")}
            />
            <RxTextInput
              labelText="Last name *"
              name="last_name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                removeFieldFromErrors("lastName");
              }}
              error={checkErrorsForField("lastName")}
            />
          </S.TwoFieldsContainer>
          <RxTextInput
            labelText="Company *"
            name="company"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
              removeFieldFromErrors("company");
            }}
            error={checkErrorsForField("company")}
          />
          <RxTextInput
            labelText="Job title *"
            name="job_title"
            value={jobTitle}
            onChange={(e) => {
              setJobTitle(e.target.value);
              removeFieldFromErrors("jobTitle");
            }}
            error={checkErrorsForField("jobTitle")}
          />
          <S.TwoFieldsContainer>
            <RxTextInput
              labelText="Email *"
              name="email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                removeFieldFromErrors("email");
              }}
              error={checkErrorsForField("email")}
            />
            <RxPhoneNumberInput
              labelText="Phone number"
              name="phone_number"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                removeFieldFromErrors("phoneNumber");
              }}
              error={checkErrorsForField("phoneNumber")}
            />
          </S.TwoFieldsContainer>
          <RxDropdownSelect
            name="reason"
            labelText="How did you hear about us?"
            placeholder="How did you hear about us?"
            selected={reason}
            onChange={(e) => setReason(e.target.value)}
            options={reasons}
          />
          <S.CheckboxesContainer>
            <RxCheckbox
              key="terms"
              name="terms"
              checkColour={colours.clubBlue}
              labelColour={props.textColour}
              labelText={
                "Hear about our events, promotions and products. Your data is secure and you can unsubscribe anytime."
              }
              onChange={() => {
                setTermsAgreed(!termsAgreed);
              }}
              isChecked={termsAgreed}
            />
          </S.CheckboxesContainer>
          {currentErrors.length > 0 && (
            <>
              <S.ErrorsContainer>
                {currentErrors.map((error) => (
                  <RxError key={error.message} message={error.message} />
                ))}
              </S.ErrorsContainer>
            </>
          )}
          <MarketingButton
            buttonText="Submit enquiry"
            colour={colours.marketingMango}
            disabled={isLoading}
            name="submit_button"
            textColour={props.buttonColour || colours.shell}
            type="button"
            onClick={handleSubmit}
          />
          <CM colour={props.textColour}>
            Submitting this form means you agree to our{" "}
            <RxLink
              href="/privacy"
              textColour={colours.marketingMango}
              textLink
            >
              Privacy Policy.
            </RxLink>
          </CM>
        </>
      )}
    </S.Form>
  );
};

const S = () => {};

S.Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

S.TwoFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
`;

S.CheckboxesContainer = styled.div`
  margin: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

S.ErrorsContainer = styled.div`
  border-radius: 0.5rem;
  padding: 3% 10%;
  background: ${colours.errorYellow};
  transition: all 0.2s ease-out;
`;

export default BusinessEnquiryForm;
