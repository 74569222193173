import Joi from "joi";

const businessSchema = Joi.object({
  first_name: Joi.string().min(1).required(),
  last_name: Joi.string().min(1).required(),
  company: Joi.string().max(30).required(),
  job_title: Joi.string().max(30).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone_number: Joi.string().min(8).max(14).optional().allow("", null),
  reason: Joi.string().optional().allow("", null),
  terms: Joi.boolean(),
  query_params: Joi.object().optional().allow(null),
});

export default businessSchema;
